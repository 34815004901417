:root {
  /* color palette from flatuicolors.com */
  --color-midnight-blue: #2c3e50;
  --color-clouds: #ecf0f1;
  --color-silver: #bdc3c7;
  --color-concrete: #95a5a6;
  --color-asbestos: #7f8c8d;
  --color-wet-asphalt: #34495e;
  --color-emerald: #2ecc71;
  --color-nephritis: #27ae60;
  --color-turquoise: #1abc9c;
  --color-green-sea: #16a085;
  --color-peter-river: #3498db;
  --color-belize-hole: #2980b9;
  --color-amethyst: #9b59b6;
  --color-wisteria: #8e44ad;
  --color-sun-flower: #f1c40f;
  --color-orange: #f39c12;
  --color-carrot: #e67e22;
  --color-pumpkin: #d35400;
  --color-alizarin: #e74c3c;
  --color-pomegranate: #c0392b;

  --color-fg: var(--color-midnight-blue);
  --color-fg-mute: var(--color-silver);
  --color-fg-invert: var(--color-clouds);
  --color-bg: var(--color-clouds);
  --color-bg-dark: var(--color-silver);
  --color-bg-invert: var(--color-wet-asphalt);
  --color-highlight: var(--color-belize-hole);
  --color-danger: var(--color-alizarin);
  --color-danger-dark: var(--color-pomegranate);

  --font-size: 16px;
  --font: var(--font-size) / 1.5 "Menlo", "DejaVu Sans Mono",
    "Bitstream Vera Sans Mono", Courier, monospace;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-highlight: var(--color-peter-river);
    --color-bg: var(--color-midnight-blue);
    --color-bg-dark: var(--color-silver);
    --color-bg-invert: var(--color-clouds);
    --color-fg: var(--color-clouds);
    --color-fg-mute: var(--color-silver);
    --color-fg-invert: var(--color-midnight-blue);
  }
}

.content,
.cm-s-mdnew.CodeMirror,
button,
html,
body {
  padding: 0;
  margin: 0;
  background-color: var(--color-bg);
  font: var(--font);
}

@media screen and (min-width: 900px) {
  :root {
    --font-size: 24px;
  }
}

.content,
.cm-s-mdnew.CodeMirror {
  width: 100vw;
  height: auto;
  max-width: 60ch;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-bg);
}

.content,
.cm-s-mdnew .CodeMirror-lines {
  padding: 25vh 1rem 75vh;
  color: var(--color-fg);
}

.content {
  box-sizing: border-box;
}

.content strong::before,
.content strong::after {
  content: "**";
  display: inline;
}

h1,
h2,
h3,
.CodeMirror .cm-header {
  color: var(--color-highlight);
  font-weight: bold;
}

p code {
  background-color: var(--color-bg-invert);
  color: var(--color-fg-invert);
  padding: 0 0.5ch;
  border-radius: 4px;
}

ol.changelog {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

blockquote {
  border-left: 2px solid var(--color-highlight);
  margin: 0;
  padding-left: 2rem;
}

.cm-s-mdnew .CodeMirror-cursor {
  background-color: var(--color-highlight);
  width: 4px;
  border: none;
  border-radius: 2px;
  animation: blink 1000ms ease-in-out infinite;
  transform: translateX(-2px);
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*
 * Navigation
 */

#nav {
  padding: 1rem;
  font-size: 0.5rem;
}

#nav a {
  color: var(--color-fg-mute);
}

/*
 * Info Page
 */

h1,
h2,
h3,
ul,
ol,
nav,
p {
  font: inherit;
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0 4px;
}

h1,
h2,
h3,
p strong {
  font-weight: bold;
}

h1,
h2,
h3 {
  margin-top: 3rem;
}

h1::before {
  content: "# ";
  display: inline;
}

h2::before {
  content: "## ";
  display: inline;
}

h3::before {
  content: "### ";
  display: inline;
}

p.danger {
  border: 1px solid var(--color-danger);
  padding: 1rem;
}

ol, ul {
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  padding-left: 4px;
  list-style-type: none;
}

ol {
  list-style-type: none;
}

ol li {
  counter-increment: olli;
}
ol li::before {
  content: counter(olli) '. ';
  /* margin-right: 1ch; */
}

ul li::before {
  content: "-";
  margin-right: 1ch;
  display: inline-block;
}

button {
  border: none;
  padding: 0.25rem 0.5rem;
  line-height: 1rem;
  vertical-align: middle;
  background-color: var(--color-bg-dark);
  color: var(--color-fg);
  box-shadow: 0 5px 0 var(--color-fg);
  cursor: pointer;
}

a:link,
a:visited {
  color: var(--color-highlight);
  text-decoration: underline;
}

.btn-danger {
  background-color: var(--color-bg);
  color: var(--color-danger);
  box-shadow: 0 5px 0 var(--color-danger);
}

.btn-danger:hover {
  background-color: var(--color-danger);
  color: var(--color-bg);
}

/*
 * Textarea fallback
 */

.fallback-note {
  color: var(--color-fg-mute);
  font-size: 0.5rem;
}

.fallback-textarea {
  width: 100%;
  box-sizing: border-box;
  min-height: 200vh;
  padding: 1rem;
  border: 1px solid var(--color-highlight);
  transition: outline 500ms ease-out;
  resize: vertical;
}
